import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [8,[2]],
		"/(app)/admin": [9,[2]],
		"/(app)/admin/users": [10,[2]],
		"/(app)/admin/users/create": [11,[2]],
		"/(app)/athletes": [12,[2]],
		"/(app)/athletes/add": [15,[2]],
		"/(app)/athletes/[id]": [13,[2]],
		"/(app)/athletes/[id]/edit": [14,[2]],
		"/auth": [30,[5]],
		"/auth/email/error": [31,[5,6]],
		"/auth/email/error/browser": [32,[5,6]],
		"/auth/email/error/link": [33,[5,6]],
		"/auth/email/sent": [34,[5,6]],
		"/auth/entering/link": [35,[5]],
		"/dev": [36],
		"/dev/angleCalc": [37],
		"/dev/bananas": [38],
		"/dev/beep": [39],
		"/dev/benchmarking": [40],
		"/dev/benchmarking/datasctructures": [41],
		"/dev/demo": [42],
		"/dev/demo/videoFilterPOA": [43],
		"/dev/ffmpeg": [44],
		"/dev/filesystem": [45],
		"/dev/jsbuild": [46],
		"/dev/mediaSource": [47],
		"/dev/plots": [48],
		"/dev/plots/[tabNumber]": [49],
		"/dev/sentry": [50],
		"/dev/slotTest": [51],
		"/dev/tfjs": [52],
		"/dev/vanillaLoad": [53],
		"/dev/webCodecs": [54],
		"/(app)/help": [16,[2]],
		"/home": [55],
		"/(app)/invites": [17,[2]],
		"/(app)/lab": [18,[2,3]],
		"/(app)/lab/[...type]": [19,[2,3]],
		"/legal/privacy": [56,[7]],
		"/legal/terms": [57,[7]],
		"/(app)/settings/app": [20,[2,4]],
		"/(app)/settings/subscriptions": [21,[2,4]],
		"/(app)/trainees": [22,[2]],
		"/(app)/trainer": [23,[2]],
		"/(app)/users/edit": [26,[2]],
		"/(app)/users/me": [27,[2]],
		"/(app)/users/[id]": [24,[2]],
		"/(app)/users/[id]/edit": [25,[2]],
		"/(app)/videos/[id]": [28,[2]],
		"/(app)/videos/[id]/edit": [29,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';